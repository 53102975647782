import { useLinkProtocols } from '/machinery/useLinkProtocols'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ButtonExternalLink.css'

import arrowUpRight from '/images/icons/arrow-up-right.raw.svg'

export function ButtonExternalLink({ href, dataX, label, layoutClassName = undefined }) {
  const { rel, target } = useLinkProtocols({ href })

  return (
    <a data-x={dataX} className={cx(styles.component, layoutClassName)} {...{ href, rel, target }} >
      <span>{label}</span>
      <Icon icon={arrowUpRight} layoutClassName={styles.iconLayout} />
    </a>
  )
}
