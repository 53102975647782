import { animated, useSprings } from '@react-spring/web'
import { useElementSize }  from '@kaliber/use-element-size'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { TitleWithDescription } from '/features/buildingBlocks/TitleWithDescription'
import { ContentColumns } from '/features/buildingBlocks/ContentColumns'
import { ImageCover } from '/features/buildingBlocks/Image'
import { TagWhiteTransparent } from '/features/buildingBlocks/Tag'
import { HeadingCondensedSm } from '/features/buildingBlocks/Heading'
import { ButtonExternalLink } from '/features/buildingBlocks/ButtonExternalLink'

import styles from './FeaturedCases.css'

export function FeaturedCases({ title, description, items }) {
  return (
    <div data-style-context='white' className={styles.component}>
      <ContainerXl>
        <div className={styles.container}>
          {title && <TitleWithDescription {...{ title, description }} />}

          {Boolean(items.length) && <CasesDesktop  {...{ items }} />}
          {Boolean(items.length) && <CasesMobile {...{ items }} />}
        </div>
      </ContainerXl>
    </div>
  )
}

function CasesMobile({ items }) {
  return (
    <div className={styles.componentCasesMobile}>
      {items.map(({ title, url, tags, image }, i) => (
        <div key={i} className={styles.mobileContentContainer}>
          <ImageCard layoutClassName={styles.imageCardLayout} {...{ image }} />
          <ContentCard layoutClassName={styles.contentCardLayout} {...{ title, url, tags }} />
        </div>
      ))}
    </div>
  )
}

function CasesDesktop({ items }) {
  const { size: { height: imageCardsHeight }, ref: imageCardsRef } = useElementSize()

  const itemsLength = items.length + 1
  const height = imageCardsHeight * itemsLength

  const thresholdPerItem = 1 / itemsLength
  const halfwayThreshold = thresholdPerItem / 2

  const [activeCard, setActiveCard] = React.useState(0)

  const [springStyles, api] = useSprings(items.length, () => ({
    clipPath: 'inset(0 round 15px)'
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(progression) {
      setActiveCard(Math.max(Math.min(items.length - 1, Math.floor((progression - halfwayThreshold) * itemsLength)), 0))

      api.start((i) => {
        if (i >= 1) {
          const rangeStart = i / itemsLength
          const rangeEnd = (i + 1) / itemsLength

          const insetTop = progression >= rangeStart && progression <= rangeEnd
            ? 100 - ((progression - rangeStart) / (rangeEnd - rangeStart)) * 100
            : progression > rangeEnd
              ? 0
              : 100

          return { clipPath: `inset(${insetTop}% 0% 0% 0% round 15px)` }
        }
      })
    }
  })

  const content = items.map(({ title, url, tags }) => ({ title, url, tags }))
  const images = items.map(({ image }) => image)

  return (
    <div style={{ '--height': `${height}px`, '--items-length': itemsLength }} className={styles.componentCasesDesktop}>
      <div className={styles.casesContainer} {...{ ref }}>
        <div className={styles.cardsContainer}>
          <ContentColumns
            renderSmallColumn={({ layoutClassName }) => <ContentCards items={content} {...{ activeCard, layoutClassName }} />}
            renderLargeColumn={({ layoutClassName }) => <ImageCardsRef ref={imageCardsRef} {...{ images, springStyles, layoutClassName }} />}
          />
        </div>
      </div>
    </div>
  )
}

function ContentCards({ items, activeCard, layoutClassName }) {
  return (
    <div className={cx(styles.componentContentCards, layoutClassName)}>
      <ul className={styles.contentCardsContainer}>
        {items?.map(({ title, url, tags }, i) => (
          <ContentCard key={i} isActive={activeCard === i} layoutClassName={styles.contentLayout} {...{ title, url, tags }} />
        ))}
      </ul>

      <ul className={styles.scrollIndicatorsContainer}>
        {items.map((_, i) => <li key={i} className={cx(styles.scrollIndicator, activeCard === i && styles.isActive)} />)}
      </ul>
    </div>
  )
}

function ContentCard({ title, url, tags, layoutClassName, isActive = undefined }) {
  return (
    <li className={cx(styles.componentContentCard, layoutClassName)}>
      {Boolean(tags?.length) && (
        <ul className={cx(styles.tagsContainer, styles.transition, isActive && styles.isActive)}>
          {tags.map((label, i) => <TagWhiteTransparent key={i} layoutClassName={styles.tagLayout} {...{ label }} />)}
        </ul>
      )}

      <div className={cx(styles.headingContainer, styles.transition, isActive && styles.isActive)}>
        <HeadingCondensedSm h={3} layoutClassName={styles.headingLayout} {...{ title }} />
      </div>

      <div className={cx(styles.buttonContainer, styles.transition, isActive && styles.isActive)}>
        <ButtonExternalLink dataX='link-to-case' href={url} label='Bekijk de case' layoutClassName={styles.buttonLayout} />
      </div>
    </li>
  )
}

const ImageCardsRef = React.forwardRef(ImageCards)

function ImageCards({ images, springStyles, layoutClassName }, ref) {
  return (
    <div className={cx(styles.componentImageCards, layoutClassName)} {...{ ref }}>
      <div className={styles.imagesContainer}>
        {springStyles.map((style, i) => (
          <ImageCard
            key={i}
            image={images[i]}
            layoutClassName={styles.imageCardLayout}
            {...{ style }}
          />
        ))}
      </div>
    </div>
  )
}

function ImageCard({ image, style = undefined, layoutClassName }) {
  return (
    <animated.div className={cx(styles.componentImageCard, layoutClassName)} {...{ style }}>
      <ImageCover aspectRatio={1} layoutClassName={styles.imageLayout} {...{ image }} />
    </animated.div>
  )
}
